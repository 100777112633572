import React, {FC} from 'react';
import { FormattedMessage } from 'react-intl';

import patternImg from './pattern.svg';

const BlogHero: FC = () => (
  <section className="blog-hero" style={{backgroundImage: `url(${patternImg})`}}>
    <div className="container">
      <div className="row">
        <div className="col-12 col-xl-10 offset-xl-1">
          <h1 className="mt-5">
            <FormattedMessage id="blog.title"/>
          </h1>
          <p className="lead">
          <FormattedMessage id="blog.description"/>
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default BlogHero;
