import React from 'react';

import { graphql } from 'gatsby';
import LocalizedLink from '../components/LocalizedLink/LocalizedLink';
import IndexLayout from '../layouts';

import Img, { FluidObject } from 'gatsby-image';
import BlogEntry from '../components/Blog/BlogEntry/BlogEntry';
import BlogHero from '../components/Blog/BlogHero/BlogHero';
import Footer from '../components/Footer/Footer';
import SEO from '../components/SEO/SEO';
import { LocaleTypes } from '../data/locales';

interface BlogPageProps {
  data: {
    site: {
      siteMetadata: {
        blogTitle: string;
        blogDescription: string;
      };
    };
    allContentfulBlogPost: {
      edges: {
        node: {
          title: string;
          slug: string;
          body: {
            childMarkdownRemark: {
              excerpt: string;
            }
          }
          date: string;
          mainImage: {
            fluid: FluidObject
          }
          author?: AuthorProps
        };
      }[];
    };
  };
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

interface BlogEntryProps {
  slug: string;
  image: FluidObject;
  title: string;
  excerpt: string;
  date: string;
  className: string;
  author?: AuthorProps;
}

interface AuthorProps {
  name: string;
  bio: string;
  profilePic: {
    resize: {
      src: string;
    };
  };
}

const BlogFeaturedEntry: React.FC<BlogEntryProps> = ({
  className,
  slug,
  image,
  title,
  excerpt,
  author,
  date
}) => (
  <div className={`blog-entry ${className}`}>
    <div className="row">
      <div className="col-12 ">
        <LocalizedLink to={`/blog/${slug}`}>
          <Img
            fluid={image}
            className="blog-big-preview-pic mb-4 mb-lg-0"
          />
        </LocalizedLink>
        <LocalizedLink to={`/blog/${slug}`}>
          <h2 className="blog-featured-entry-title mt-3">
            {title}
          </h2>
          <p className="lead text-muted blog-featuredEntry-text mb-2">
            {excerpt}
          </p>
        </LocalizedLink>
        <div className="blog-entry-footer">
          {author ?
            <div className="author">
              <img src={author.profilePic.resize.src} />
              <div>
                <p className="authorName">{author.name}</p>
                <p className="authorBio">{author.bio}</p>
              </div>
            </div> : ''
          }
          <small>{date}</small>
      </div>
      {/* <div className="col-12 col-lg-4">
        
        </div> */}
      </div>
    </div>
  </div>
);

const BlogPage: React.FC<BlogPageProps> = ({data, pathContext, location}) => {
  const content = (): React.ReactNode => {
    const posts = data.allContentfulBlogPost.edges.slice(1);
    const firstPost = data.allContentfulBlogPost.edges[0].node;
    const blogContent = data.site.siteMetadata;

    return (
      <>
        <SEO
          titleId="blog.title"
          descriptionId="blog.description"
        />
        <BlogHero />
        <div className="container py-4">
          <div className="row">
            <div className="col-12 col-xl-10 offset-xl-1">
              <BlogFeaturedEntry
                className="mt-4"
                slug={firstPost.slug}
                image={firstPost.mainImage.fluid}
                title={firstPost.title}
                excerpt={firstPost.body.childMarkdownRemark.excerpt}
                date={firstPost.date}
                author={firstPost.author}
              />
              <section className="section mb-5">
                <div className="row">
                  {posts.map(({ node }: any) => {
                    return (
                      <div className="col-md-6 col-lg-4" key={node.title}>
                        <BlogEntry
                          className="mt-4"
                          slug={node.slug}
                          image={node.mainImage.fluid}
                          title={node.title}
                          excerpt={node.body.childMarkdownRemark.excerpt}
                          date={node.date}
                          author={node.author}
                          />
                      </div>
                    );
                  })}
                </div>
              </section>
            </div>
            </div>
          </div>
        <Footer theme="primary" />
      </>
    );
  }

  return (
    <IndexLayout navbarTheme="light" render={ content } locale={pathContext.localeCode} location={location}/>
  );
}

export default BlogPage;

export const query = graphql`
  query($localeCode: String!) {
    site {
      siteMetadata {
        blogTitle
        blogDescription
      }
    }
    allContentfulBlogPost(sort: {order: DESC, fields: date}, filter: {node_locale: {eq: $localeCode}}) {
      edges {
        node {
          title
          slug
          body {
            childMarkdownRemark {
              excerpt
            }
          }
          date(formatString: "MMMM DD, YYYY", locale: $localeCode)
          author {
            bio
            name
            profilePic {
              resize(width: 60, height: 60, quality: 75) {
                src
              }
            }
          }
          mainImage {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`;
